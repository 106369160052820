/* Most used passenger car graph styling */
.graph-container {
  position: relative;
  height: 215px;
  width: fit-content;
  margin: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 25px;
}

.circle-container {
  position: relative;
  height: 90px;
  width: auto;
  top: 75px;
}

.half-circle {
  position: absolute;
  height: 90px;
  width: 45px;
  border-bottom-right-radius: 90px;
  border-top-right-radius: 90px;
  background: transparent;
  border: 2px solid #cecece;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: 35px;
}

.graph-circle {
  background-color: rgb(255, 115, 0);
  position: absolute;
  height: 70px;
  width: 70px;
  border-radius: 70px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  top: 10px;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;

}

.l {
  z-index: 100000;
  border-right: 2px solid #cecece;
  -moz-transform: skew(-45deg);
  -webkit-transform: skew(-45deg);
  transform: skew(-45deg);
  height: 28px;
  width: 64px;
  margin-left: 2px;
}

.l-4 {
  -moz-transform: skew(45deg);
  -webkit-transform: skew(45deg);
  transform: skew(45deg);
}

.l-5 {
  -moz-transform: skew(45deg);
  -webkit-transform: skew(45deg);
  transform: skew(45deg);
}

.line-s {
  z-index: 100000;
  height: 2px;
  width: 26px;
  background-color: #cecece;
}

.rank-row {
  position: absolute;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.rank-row-1 {
  margin-top: -39px;
}

.rank-row-2 {
  margin-top: -6px;
  margin-left: 28px;
}

.rank-row-3 {
  margin-top: 44px;
  margin-left: 70px;
}

.rank-row-4 {
  margin-top: 25px;
  margin-left: 27px;
}

.rank-row-5 {
  margin-top: 60px;
  margin-left: 0px;
}

.rank {
  text-align: center;
  font-weight: bold;
  color: rgb(43, 92, 138);
  background: white;
  -webkit-font-smoothing: antialiased;
  width: 15px;
  border-radius: 50%;
  margin-right: 4px;
  font-size: 10px;
  height: 15px;
  border: 1px solid #cecece;
  line-height: 14px;
}

.rank-box {
  background-color: rgb(43, 92, 138);
  width: fit-content;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 5px;
  color: white;
  display: flex;
  line-height: 16px;
  white-space: nowrap;
}

.r {
  margin-left: 12px;
  margin-top: -27px;
  display: flex;
  align-items: center;
}

img {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

@keyframes opacition {
  0% {opacity: 0; opacity: 0.25;}
  50% {opacity: 0.25; opacity: 0.5;}
  100% {opacity: 0.5; opacity: 1;}
}

.ui[class*="very wide"].popup {
  max-width: 1160px !important;
}

@font-face {
  font-family: "Gotham-Bold";
  src: url("fonts/Gotham-Bold.eot");
  src: url("fonts/Gotham-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/Gotham-Medium.otf") format("opentype"),
    url("fonts/Gotham-Medium.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  /* 
  Unsures text remains visible during font load
  for more detail;  
  https://developers.google.com/web/updates/2016/02/font-display?utm_source=lighthouse&utm_medium=cli
  */
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham.eot");
  src: url("fonts/Gotham.eot?#iefix") format("embedded-opentype"),
    url("fonts/Gotham-Book.otf") format("opentype"),
    url("fonts/Gotham-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  /* 
  Unsures text remains visible during font load
  for more detail;  
  https://developers.google.com/web/updates/2016/02/font-display?utm_source=lighthouse&utm_medium=cli
  */
  font-display: swap;
}

@media only screen and (min-width: 767px) {
  .bigScreenMenu {
    display: flex !important;
  }
}

.menuTogglespan {
  display: block;
  width: 24px;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  background: rgb(0, 130, 60);
  border-radius: 3px;
}
/* MANN HUMMEL CUSTOM ELEMENTS */

.ui.grey.button {
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
  -moz-box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
  -webkit-box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
  -ms-box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
  -o-box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
}

.ui.green.table {
  border-top: 0.2em solid rgb(40, 121, 70);
}

.ui.labeled.input > .label {
  font-weight: 600 !important;
}

.ui.form .field > label {
  font-weight: 600 !important;
}

.ui.label,
.ui.labels .label {
  justify-content: center;
  display: flex;
  align-items: center;
}

/* MANN HUMMEL CUSTOM ELEMENTS */

*:not(i) {
  font-family: "Gotham", "Gotham-Book", "Open Sans" !important;
}

body,
html {
  padding: 0;
  margin: 0;
}

body {
  padding-top: 73px;
}

.blurring {
  padding-top: 14px;
}

.bg {
  outline: none !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 40px);
  background: rgb(250, 250, 250);
  background: linear-gradient(
    90deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(250, 250, 250, 1) 100%
  );
}

#svg-map path {
  cursor: pointer;
}

.svg-map {
  max-width: 1007px !important;
  margin: 0 auto;
  /* height: 760px; */
  text-align: center;
}

.svg-map svg {
  width: 100%;
  height: auto;
}

.svg-hidden {
  opacity: 0;
}

.svg-shadow {
  -webkit-filter: drop-shadow(-3px 2px 2px rgba(136, 136, 136, 0.5));
  filter: drop-shadow(-3px 2px 2px rgba(136, 136, 136, 0.5));
}

.fadein {
  opacity: 1;
  transition: all 2s ease-in;
  -moz-transition: all 2s ease-in;
  -o-transition: all 2s ease-in;
  -ms-transition: all 2s ease-in;
  transition: all 2s ease-in;
}

.fadeout {
  opacity: 0;
  transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  -ms-transition: all 2s ease-out;
  transition: all 2s ease-out;
}

.ui.secondary.menu .active.item {
  color: rgb(58, 132, 73) !important;
  background-color: transparent;
}

.highcharts-pie-series .highcharts-point {
  stroke: #ede;
  stroke-width: 2px;
}

.highcharts-pie-series .highcharts-data-label-connector {
  stroke: silver;
  stroke-dasharray: 2, 2;
  stroke-width: 2px;
}

.custom-header {
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #dadada;
  position: fixed;
  z-index: 1000;
  right: 0;
  left: 0;
  top: 0;
}

.header-image {
  fill: #3a8449;
}

::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}

.no-pointer-event {
  pointer-events: none;
}

.custom-toggle {
  cursor: pointer;
  height: 38px;
  line-height: 37px;
  width: 65px;
  border: 1.5px solid rgb(2, 138, 195);
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.\.progressbar-container svg {
  border-radius: 3px !important;
}

.new-input-container {
  border: 1.5px solid rgb(58, 132, 73);
  border-radius: 5px;
  position: relative;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.new-input-container h2 {
  top: -17px;
  position: absolute;
  background-color: white;
  color: rgb(58, 132, 73);
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  opacity: 1 !important;
}

input:active,
input:focus,
textarea:active,
textarea:focus,
select:active,
select:focus,
.ui.dropdown:active,
.ui.dropdown:focus,
.ui.dropdown.active,
.ui .menu.visible {
  border-color: rgb(58, 132, 73) !important;
}

input:focus:checked ~ .box:before,
input:focus:checked ~ label:before {
  border-color: rgb(58, 132, 73) !important;
}

.box:before,
label:before {
  border-color: rgb(58, 132, 73) !important;
}

input:checked ~ .box:before,
input:checked ~ label:before {
  border-color: rgb(58, 132, 73) !important;
}

.selected-perc-btn {
  background-color: rgb(255, 115, 0) !important;
}

.width-100 {
  width: 100%;
}

.filepond--drop-label label {
  font-size: 14px;
  line-height: inherit;
}

.filepond--label-action {
  font-size: 14px;
  font-weight: 800;
  color: rgb(58, 132, 73) !important;
}

.suir-calendar {
  cursor: pointer !important;
}

.custom-error-label {
  color: #db2828;
  margin-top: 5px;
  font-size: 13px;
  text-align: center;
}

.ui.labeled.input:not([class*="corner labeled"]) .label:first-child {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.filepond--root {
  margin-bottom: 0;
}

.default-page-padding {
  padding-top: 40px !important;
}

.ui.dropdown {
  font-weight: 300;
}

.header.item {
  background-color: transparent !important;
}

.tool-tip-label {
  display: block;
  color: rgba(0, 0, 0, 0.7);
  background-color: white;
  margin-left: 7;
}

.loader {
  top: 200px !important;
}

.dimmed.dimmable > .ui.animating.dimmer,
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
  display: flex !important;
  align-items: center !important;
}

.ui.modal,
.ui.active.modal {
  margin: 0 auto !important;
  top: auto !important;
  left: auto !important;
  transform-origin: center !important;
  transition: all ease 0.5s;
}

.visible.transition {
  margin-top: auto !important;
  display: inline-block !important;
  position: relative;
  top: 20%;
}

.const-table {
  border: 0 !important;
}

.const-table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.rbc-today {
  background-color: rgba(0, 130, 60, 0.15) !important;
}

.rbc-btn-group button {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #ccc !important;
}

.rbc-time-view {
  border: none;
}

.rbc-time-content {
  display: none;
}

.rbc-time-header {
  border: 1px solid #ddd;
}

.rbc-agenda-date-cell {
  background-color: rgba(256, 256, 256, 1);
}

.rbc-agenda-empty {
  text-align: center;
}

.ui.dropdown > .text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: visible;
  width: 100%;
  display: block;
}

.objective-text .progressbar-text {
  font-size: 13px;
}

.ui.form .field > label {
  font-size: 14px !important;
}

.bold {
  -webkit-font-smoothing: antialiased;
  font-weight: bold !important;
}

.DraftEditor-root {
  max-height:300px;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  width: 100%;
}

.editor-color-picker{
  right: 0;
  left: unset !important;
}

.editor-color-picker .rdw-colorpicker-modal-options {
  margin: auto;
  overflow: hidden;
  height: auto;
}